.login{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.proms-list{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width:30%;
}

.proms{
    padding: 5px;
}

.big-buque{
    width: 75%;
    height: 20%;
    font-size: 5em;
    text-align: center;
    background-color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
