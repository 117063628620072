html {
    height: 100%;
}
body {
    min-height: 100%;
    height: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'DejaVu Sans Mono', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    color: white;
    font-weight: bold;
    overflow: hidden;
}

#root {
    height: 100%
}

input {
    background-color: black;
    color: white;
    border: none;
    outline: none;
    height: 100px;
    font-size: 3em;
    text-align: center;
}

table, th, td {
    border: 1px solid white;
    font-weight: normal;
    text-align: left;
}

th {
    box-shadow: 1px 1px 1px #838585;

}

thead{
    background-color: #e9f3f3;
    color: black;
}
table{
    border-collapse: collapse;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.--big {
    font-size: 2em;
}

.--medium {
    font-size: 1.2em;
}
